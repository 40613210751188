import { of } from 'rxjs';

import { generateNavigationRoutes, NavigationRoute } from '@ggp/generic/shared/util/feature-flag';

import { environment } from '../environments/environment';

export const DEFAULT_ROUTE = '/leads/public-projects';

const initialRoutes: NavigationRoute[] = [
  {
    id: 0,
    key: 'home',
    data_test_id: 'public-projects',
    redirection: '/leads/public-projects',
    queryParams: {
      projectType: 'PUBLIC_TENDER',
    },
    flag: true,
  },
  {
    id: 1,
    key: 'construction',
    data_test_id: 'construction-projects',
    redirection: '/leads/construction-projects',
    queryParams: {
      projectType: 'PRIVATE_PROJECT',
    },
  },
  {
    id: 2,
    key: 'building-types',
    redirection: '/leads/companies',
    queryParams: {
      projectType: 'COMPANIES',
    },
    flag: environment.features.showCompanies,
  },
  {
    id: 3,
    key: 'just-ask',
    redirection: '/just-ask',
    flag: environment.features.showJustAsk,
  },
  {
    id: 4,
    key: 'book',
    data_test_id: 'help-section',
    redirection: '/help',
  },
];

export const NavigationRoutes = of(generateNavigationRoutes(initialRoutes));
