/* eslint-disable @typescript-eslint/no-explicit-any */
import { bootstrapApplication } from '@angular/platform-browser';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';
import { gtag } from '@ggp/generic/shared/util/gtag';
import { didomi } from '@ggp/generic/shared/util/didomi';

gtag(window, document, 'script', 'dataLayer', environment.gtag_id);

didomi(window, environment.didomi_id);

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
