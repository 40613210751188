export const SocialMedia = [
  {
    key: 'linkedin',
    url: 'https://be.linkedin.com/company/ebp',
  },
  {
    key: 'youtube',
    url: 'https://www.youtube.com/channel/UCxm2y4DbJgRa7UowJmr2arw',
  },
  {
    key: 'website',
    url: 'https://www.ebp.be/',
  },
];
