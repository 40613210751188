import { Route } from '@angular/router';
import { AuthGuard, CommonGuard, JustAskGuard } from '@ggp/generic/auth';
import { LayoutComponent } from '@ggp/generic/shared/components/layout';
import { Page404Component } from '@ggp/generic/shared/components/page-404';
import { contactIcons, filtersIcons, justAskIcons, leadsIcons, leftBarIcons, topBarIcons } from '@ggp/generic/shared/icons';
import { provideSvgIcons } from '@ngneat/svg-icon';

import { environment } from '../environments/environment';

export const appRoutes: Route[] = [
  {
    path: 'auth',
    canActivate: [CommonGuard],
    providers: [provideSvgIcons([...contactIcons])],
    loadChildren: () => import('@ggp/ebp/auth/feature').then(mod => mod.AUTH_ROUTES),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    providers: [provideSvgIcons([...leftBarIcons, ...topBarIcons, ...filtersIcons, ...leadsIcons, ...justAskIcons])],
    children: [
      {
        path: '',
        redirectTo: 'leads',
        pathMatch: 'full',
      },
      {
        path: 'account',
        loadChildren: () => import('@ggp/generic-shared-components-feature').then(mod => mod.MY_ACCOUNT_ROUTES),
      },
      {
        path: 'just-ask',
        loadChildren: () => import('@ggp/generic/shared/components/just-ask').then(mod => mod.JUST_ASK_ROUTES),
        canActivate: [JustAskGuard],
      },
      {
        path: 'leads',
        loadChildren: () => import('@ggp/ebp/leads/feature').then(mod => mod.generateRoutes(environment)),
      },
      {
        path: 'help',
        loadChildren: () => import('@ggp/generic/shared/components/help-section').then(mod => mod.HELP_SECTION_ROUTES),
      },
      { path: '**', redirectTo: '/not-found' },
    ],
  },
  {
    path: 'not-found',
    component: Page404Component,
  },
];
