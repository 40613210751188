import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { ProductsService } from '@ggp/generic/shared/services';
import { map } from 'rxjs';

export const JustAskGuard = () => {
  const router = inject(Router);

  return inject(ProductsService).products$.pipe(
    map(({ data }) => {
      const isJustAsk = data.some(({ product_id }) => product_id === 'justask');

      if (!isJustAsk) {
        router.navigate(['/leads/public-projects']);
      }

      return isJustAsk;
    }),
  );
};
