import { ApolloModule } from 'apollo-angular';
import { Amplify } from 'aws-amplify';

import { registerLocaleData } from '@angular/common';
import { HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { MAT_RIPPLE_GLOBAL_OPTIONS, MatNativeDateModule } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, RouterModule } from '@angular/router';
import { EbpActionButtonService, EbpCompanyService, EbpDocsService, EbpNotificationService, EbpSubscriptionService, EpbLeadsService } from '@ggp/ebp/leads/ui';
import { AuthInterceptor, loadCurrentProvider } from '@ggp/generic/auth';
import { translationLoader } from '@ggp/generic/i18n';
import { apolloGraphqlProvider } from '@ggp/generic/shared/config/apollo-graphql';
import { getAppConfigProvider } from '@ggp/generic/shared/config/app';
import {
  Brand,
  BRAND_TOKEN,
  DEFAULT_ROUTE_TOKEN,
  LANGUAGE_FILTER_TOKEN,
  PLATFORM_TOKEN,
  PRODUCT_TYPES_TOKEN,
  PROJECT_TYPES_TOKEN,
  PUBLIC_STAGES_ENUM_TOKEN,
  PUBLIC_STAGES_TOKEN,
  ROUTE_CONFIG_TOKEN,
  SOCIAL_MEDIA_TOKEN,
} from '@ggp/generic/shared/config/token';
import { detailViewIcons, generalIconsIcons, ggpIconPlaceholderIcon } from '@ggp/generic/shared/icons';
import { ActionButtonService, CompanyService, DocsService, LeadsService, NotificationService, SubscriptionService } from '@ggp/generic/shared/services';
import { provideSvgIcons, provideSvgIconsConfig } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';

import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';
import { LanguageEnum } from './language';
import { DEFAULT_ROUTE, NavigationRoutes } from './navigationRoutes';
import { ProductTypes } from './product-types';
import { ProjectTypesEnum } from './project-types';
import { PublicStageEnum, PublicStages } from './public-stages';
import { SocialMedia } from './socialMedia';

registerLocaleData(localeFr);
registerLocaleData(localeEn);
registerLocaleData(localeDe);
registerLocaleData(localeNl);

Amplify.configure(environment.endpoints.app);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    provideHttpClient(),
    ...loadCurrentProvider,
    importProvidersFrom(
      BrowserModule,
      HttpClientModule,
      RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking', bindToComponentInputs: true, onSameUrlNavigation: 'reload' }),
      ApolloModule,
      MatNativeDateModule,
      TranslateModule.forRoot(translationLoader),
      MatSnackBarModule,
      NoopAnimationsModule,
      BrowserAnimationsModule,
    ),
    getAppConfigProvider(environment),
    apolloGraphqlProvider(environment),
    provideHttpClient(withInterceptors([AuthInterceptor])),
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline', floatLabel: 'always' } },
    provideSvgIconsConfig({
      sizes: {
        xs: '10px',
        sm: '16px',
        md: '24px',
        lg: '48px',
      },
      defaultSize: 'md',
    }),
    provideSvgIcons([ggpIconPlaceholderIcon, ...generalIconsIcons, ...detailViewIcons]),
    { provide: PLATFORM_TOKEN, useValue: Brand.EBP },
    { provide: ROUTE_CONFIG_TOKEN, useValue: NavigationRoutes },
    { provide: BRAND_TOKEN, useValue: { isBackOffice: false, info: 'LOGIN.SUBTITLE', url: 'https://www.ebp.be' } },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    { provide: ActionButtonService, useClass: EbpActionButtonService },
    { provide: DocsService, useClass: EbpDocsService },
    { provide: NotificationService, useClass: EbpNotificationService },
    { provide: SubscriptionService, useClass: EbpSubscriptionService },
    { provide: LeadsService, useClass: EpbLeadsService },
    { provide: CompanyService, useClass: EbpCompanyService },
    { provide: DEFAULT_ROUTE_TOKEN, useValue: DEFAULT_ROUTE },
    { provide: SOCIAL_MEDIA_TOKEN, useValue: SocialMedia },
    { provide: PUBLIC_STAGES_TOKEN, useValue: PublicStages },
    { provide: PUBLIC_STAGES_ENUM_TOKEN, useValue: PublicStageEnum },
    { provide: PROJECT_TYPES_TOKEN, useValue: ProjectTypesEnum },
    { provide: LANGUAGE_FILTER_TOKEN, useValue: { languageEnum: LanguageEnum, constructionDisplay: true } },
    { provide: PRODUCT_TYPES_TOKEN, useValue: ProductTypes },
  ],
};
