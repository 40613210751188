import { JustAskOutput } from '@ggp/generic/shared/util/models';
import { ProjectType } from './enums';

export interface JustAskQuery {
  justAskQuery: JustAskOutput;
}

export interface JustAskInputQuery {
  query: string;
  projectType: ProjectType;
  lang: string;
}

export type JustAskInput = Omit<JustAskInputQuery, 'lang'>;

export enum FeedbackKeys {
  NARRATIVE = 'NARRATIVE',
  RESULTS = 'RESULTS',
}

export interface Feedback {
  [FeedbackKeys.NARRATIVE]: string;
  [FeedbackKeys.RESULTS]: string;
}
